<template>
  <div class="preview-box">
    <v-container>
      <v-row>
        <v-col class="col-8">&nbsp;</v-col>
        <v-col class="col-4">
          <img width="180px" src="../assets/logo.png" alt="" />
        </v-col>
      </v-row>
      <v-row class="bg-orange">
        <v-col class="col-12 address-corner print-no-to-marg">
          <h2 class="terms-heading">
            Terms and conditions for the supply of goods and services to Riggott &amp; Company
            Limited
          </h2>
        </v-col>
      </v-row>
      <v-row class="print-no-top-marg">
        <v-col>
          <p class="numbered-para" data-number="1.">
            <span class="text-heavy">Interpretation</span>
          </p>
          <p>
            The following definitions and rules of interpretation apply in these Conditions.
          </p>
          <p class="numbered-para" data-number="1.1">
            Definitions:
          </p>
          <p>
            <span class="text-heavy">Business Day:</span> a day other than a Saturday, Sunday or public holiday in England, when banks in London are open for business.
          </p>
          <p>
            <span class="text-heavy">Business Hours:</span> the period from 9.00 am to 5.00 pm on any Business Day.
          </p>
          <p>
            <span class="text-heavy">Commencement Date:</span> has the meaning given in clause 2.2.
          </p>
          <p>
            <span class="text-heavy">Conditions:</span> these terms and conditions as amended from time to time in accordance with clause 18.9.
          </p>
          <p>
            <span class="text-heavy">Contract:</span> the contract between the Customer and the Supplier for the supply of Goods and/or Services in accordance with these Conditions. 
          </p>
          <p>
            <span class="text-heavy">Control:</span> has the meaning given in section 1124 of the Corporation Tax Act 2010, and the expression change of Control shall be interpreted accordingly.
          </p>
          <p>
            <span class="text-heavy">Customer:</span> Riggott and Company Limited registered in England and Wales with company number 02985947.
          </p>
          <p>
            <span class="text-heavy">Customer Materials:</span> has the meaning set out in clause 5.3(i).
          </p>
          <p>
            <span class="text-heavy">Deliverables:</span> all documents, products and materials developed by the Supplier or its agents, contractors and employees as part of or in relation to the Services in any form or media, including drawings, maps, plans, diagrams, designs, pictures, computer software and programs, data, specifications and reports (including drafts).
          </p>
          <p>
            <span class="text-heavy">Delivery Location:</span> has the meaning given in clause 4.2(b).
          </p>
          <p>
            <span class="text-heavy">Goods:</span> the goods (or any part of them) set out in the Order. 
          </p>
          <p>
            <span class="text-heavy">Goods Specification:</span> any specification for the Goods, including any related plans and drawings, that is agreed in writing by the Customer and the Supplier.
          </p>
          <p>
            <span class="text-heavy">Intellectual Property Rights:</span> patents, rights to inventions, copyright and related rights, moral rights, trade marks and service marks, business names and domain names, rights in get-up, goodwill and the right to sue for passing off, rights in designs, rights in computer software, database rights, rights to use, and protect the confidentiality of, confidential information (including know-how), and all other intellectual property rights, in each case whether registered or unregistered and including all applications and rights to apply for and be granted, renewals or extensions of, and rights to claim priority from, such rights and all similar or equivalent rights or forms of protection which subsist or will subsist now or in the future in any part of the world.
          </p>
          <p>
            <span class="text-heavy">Order:</span> the Customer's order for the supply of Goods and/or Services, as set out in the Customer's purchase order form or as otherwise communicated in writing, as the case may be.
          </p>
          <p>
            <span class="text-heavy">Services:</span> the services, including any Deliverables, to be provided by the Supplier under the Contract as set out in the Service Specification.
          </p>
          <p>
            <span class="text-heavy">Service Specification:</span> the description or specification for Services agreed in writing by the Customer and the Supplier. 
          </p>
          <p>
            <span class="text-heavy">Supplier:</span> the person or firm from whom the Customer purchases the Goods and/or Services.
          </p>
          <p>
            <span class="text-heavy">UK GDPR:</span> has the meaning given to it in section 3(10) (as supplemented by section 205(4)) of the Data Protection Act 2018.
          </p>
          <p class="numbered-para" data-number="1.2">
            Interpretation:
          </p>
          <p>
            (a) A person includes a natural person, corporate or unincorporated body (whether or not having separate legal personality). 
          </p>
          <p>
            (b) A reference to a party includes its successors and permitted assigns.
          </p>
          <p>
            (c) A reference to legislation or a legislative provision is a reference to it as amended or re-enacted. A reference to legislation or a legislative provision includes all subordinate legislation made under that legislation or legislative provision.
          </p>
          <p>
            (d) Any words following the terms including, include, in particular, for example or any similar expression shall be interpreted as illustrative and shall not limit the sense of the words preceding those terms.
          </p>
          <p>
            (e) A reference to writing or written excludes fax but includes email.
          </p>
          <p class="numbered-para" data-number="2.">
            <span class="text-heavy">Basis of contract</span>
          </p>
          <p class="numbered-para" data-number="2.1">
            The Order constitutes an offer by the Customer to purchase Goods and/or Services from the Supplier in accordance with these Conditions. 
          </p>
          <p class="numbered-para" data-number="2.2">
            The Order shall be deemed to be accepted on the earlier of:
          </p>
          <p>
            (a) the Supplier issuing written acceptance of the Order; or
          </p>
          <p>
            (b) any act by the Supplier consistent with fulfilling the Order,
          </p>
          <p>
            (c) at which point and on which date the Contract shall come into existence <span class="text-heavy">(Commencement Date)</span>.
          </p>
          <p class="numbered-para" data-number="2.3">
            These Conditions apply to the Contract to the exclusion of any other terms that the Supplier seeks to impose or incorporate, or which are implied by law, trade custom, practice or course of dealing. 
          </p>
          <p class="numbered-para" data-number="2.4">
            All of these Conditions shall apply to the supply of both Goods and Services except where the application to one or the other is specified.
          </p>
          <p class="numbered-para" data-number="2.5">
            The Supplier waives any right it might otherwise have to rely on any term endorsed upon, delivered with or contained in any documents of the Supplier that is inconsistent with these Conditions.
          </p>
          <p class="numbered-para" data-number="3.">
            <span class="text-heavy">Supply of Goods</span>
          </p>
          <p class="numbered-para" data-number="3.1">
            The Supplier shall ensure that the Goods shall:
          </p>
          <p>
            (a) correspond with their description and any applicable Goods Specification;
          </p>
          <p>
            (b) be of satisfactory quality (within the meaning of the Sale of Goods Act 1979) and fit for any purpose held out by the Supplier or made known to the Supplier by the Customer, expressly or by implication, and in this respect the Customer relies on the Supplier's skill and judgement;
          </p>
          <p>
            (c) where they are manufactured products, be free from defects in design, materials and workmanship and remain so for [12] months after delivery or in accordance with the manufacturer’s guarantee (whichever is the longer); and
          </p>
          <p>
            (d) comply with all applicable statutory and regulatory requirements relating to the manufacture, labelling, packaging, storage, handling and delivery of the Goods. 
          </p>
          <p class="numbered-para" data-number="3.2">
            The Supplier shall ensure that at all times it has and maintains all the licences, permissions, authorisations, consents and permits that it needs to carry out its obligations under the Contract in respect of the Goods.
          </p>
          <p class="numbered-para" data-number="3.3">
            The Customer may inspect and test the Goods at any time before delivery. The Supplier shall remain fully responsible for the Goods despite any such inspection or testing and any such inspection or testing shall not reduce or otherwise affect the Supplier's obligations under the Contract.
          </p>
          <p class="numbered-para" data-number="3.4">
            If following such inspection or testing the Customer considers that the Goods do not comply or are unlikely to comply with the Supplier's undertakings at clause 3.1, the Customer shall inform the Supplier and the Supplier shall immediately take such remedial action as is necessary to ensure compliance.
          </p>
          <p class="numbered-para" data-number="3.5">
            The Customer may conduct further inspections and tests after the Supplier has carried out its remedial actions.
          </p>
          <p class="numbered-para" data-number="4.">
            <span class="text-heavy">Delivery of Goods</span>
          </p>
          <p class="numbered-para" data-number="4.1">
            The Supplier shall ensure that:
          </p>
          <p>
            (a) the Goods are properly packed and secured in such manner as to enable them to reach their destination in good condition;
          </p>
          <p>
            (b) each delivery of the Goods is accompanied by a delivery note which shows the date of the Order, the Order number (if any), the type and quantity of the Goods (including the code number of the Goods (where applicable)), special storage instructions (if any) and, if the Goods are being delivered by instalments, the outstanding balance of Goods remaining to be delivered; and
          </p>
          <p>
            (c) it states clearly on the delivery note any requirement for the Customer to return any packaging material for the Goods to the Supplier. Any such packaging material shall only be returned to the Supplier at the cost of the Supplier.
          </p>
          <p class="numbered-para" data-number="4.2">
            The Supplier shall deliver the Goods: 
          </p>
          <p>
            (a) on the date specified in the Order or, if no such date is specified, then within [28] days of the date of the Order;
          </p>
          <p>
            (b) to the Customer's premises at Unit X, Lodge Lane, Tuxford, Newark Notts NG22 0NL or such other location as is set out in the Order or as instructed by the Customer before delivery (<span class="text-heavy">Delivery Location</span>); and
          </p>
          <p>
            (c) during the Customer's normal hours of business, or as instructed by the Customer.
          </p>
          <p class="numbered-para" data-number="4.3">
            Delivery of the Goods shall be completed on the completion of unloading of the Goods at the Delivery Location.
          </p>
          <p class="numbered-para" data-number="4.4">
            If the Supplier:
          </p>
          <p>
            (a) delivers less than [95]% of the quantity of Goods ordered, the Customer may reject the Goods; or
          </p>
          <p>
            (b) delivers more than [105]% of the quantity of Goods ordered, the Customer may at its sole discretion reject the Goods or the excess Goods,
          </p>
          <p>
            and any rejected Goods shall be returnable at the Supplier's risk and expense. If the Supplier delivers more or less than the quantity of Goods ordered, and the Customer accepts the delivery, the Supplier shall make a pro rata adjustment to the invoice for the Goods.
          </p>
          <p class="numbered-para" data-number="4.5">
            The Supplier shall not deliver the Goods in instalments without the Customer's prior written consent. Where it is agreed that the Goods are delivered by instalments, they may be invoiced and paid for separately. However, failure by the Supplier to deliver any one instalment on time or at all or any defect in an instalment shall entitle the Customer to the remedies set out in clause 6.1.
          </p>
          <p class="numbered-para" data-number="4.6">
            Title and risk in the Goods shall pass to the Customer on completion of delivery. 
          </p>
          <p class="numbered-para" data-number="5.">
            <span class="text-heavy">Supply of Services</span>
          </p>
          <p class="numbered-para" data-number="5.1">
            The Supplier shall from the date set out in the Order and for the duration of the Contract supply the Services to the Customer in accordance with the terms of the Contract. 
          </p>
          <p class="numbered-para" data-number="5.2">
            The Supplier shall meet any performance dates for the Services specified in the Order or that the Customer notifies to the Supplier and time is of the essence in relation to any of those performance dates. 
          </p>
          <p class="numbered-para" data-number="5.3">
            In providing the Services, the Supplier shall:
          </p>
          <p>
            (a) co-operate with the Customer in all matters relating to the Services, and comply with all instructions of the Customer;
          </p>
          <p>
            (b) perform the Services with the best care, skill and diligence in accordance with best practice in the Supplier's industry, profession or trade; 
          </p>
          <p>
            (c) use personnel who are suitably skilled and experienced to perform tasks assigned to them, and in sufficient number to ensure that the Supplier's obligations are fulfilled in accordance with the Contract;
          </p>
          <p>
            (d) ensure that the Services will conform with all descriptions, standards and specifications set out in the Service Specification, and that the Deliverables shall be fit for any purpose that the Customer expressly or impliedly makes known to the Supplier; 
          </p>
          <p>
            (e) provide all equipment, tools and vehicles and such other items as are required to provide the Services;
          </p>
          <p>
            (f) use the best quality goods, materials, standards and techniques, and ensure that the Deliverables, and all goods and materials supplied and used in the Services or transferred to the Customer, will be free from defects in workmanship, installation and design;
          </p>
          <p>
            (g) obtain and at all times maintain all licences and consents which may be required for the provision of the Services;
          </p>
          <p>
            (h) observe all health and safety rules and regulations and any other security requirements that apply at any of the Customer's premises;
          </p>
          <p>
            (i) hold all materials, equipment and tools, drawings, specifications and data supplied by the Customer to the Supplier (Customer Materials) in safe custody at its own risk, maintain the Customer Materials in good condition until returned to the Customer, and not dispose of or use the Customer Materials other than in accordance with the Customer's written instructions or authorisation;
          </p>
          <p>
            (j) not do or omit to do anything which may cause the Customer to lose any licence, authority, consent or permission upon which it relies for the purposes of conducting its business, and the Supplier acknowledges that the Customer may rely or act on the Services;
          </p>
          <p>
            (k) comply with any additional obligations as set out in the Service Specification.
          </p>
          <p class="numbered-para" data-number="6.">
            <span class="text-heavy">Customer remedies</span>
          </p>
          <p class="numbered-para" data-number="6.1">
            If the Supplier fails to deliver the Goods by the applicable date or to perform the Services by the applicable date, or both, the Customer shall, without limiting or affecting other rights or remedies available to it, have any one or more of the following rights and remedies: 
          </p>
          <p>
            (a) to terminate the Contract with immediate effect by giving written notice to the Supplier;
          </p>
          <p>
            (b) to refuse to accept any subsequent performance of the Services and/or delivery of the Goods which the Supplier attempts to make;
          </p>
          <p>
            (c) to recover from the Supplier any costs incurred by the Customer in obtaining substitute goods and/or services from a third party;
          </p>
          <p>
            (d) to require a refund from the Supplier of sums paid in advance for Services that the Supplier has not provided and/or Goods that it has not delivered; and
          </p>
          <p>
            (e) to claim damages for any additional costs, loss or expenses incurred by the Customer which are in any way attributable to the Supplier's failure to meet such dates. 
          </p>
          <p class="numbered-para" data-number="6.2">
            If the Supplier has delivered Goods that do not comply with the undertakings set out in clause 3.1, then, without limiting or affecting other rights or remedies available to it, the Customer shall have one or more of the following rights and remedies, whether or not it has accepted the Goods:
          </p>
          <p>
            (a) to terminate the Contract with immediate effect by giving written notice to the Supplier;
          </p>
          <p>
            (b) to reject the Goods (in whole or in part) whether or not title has passed and to return them to the Supplier at the Supplier's own risk and expense;
          </p>
          <p>
            (c) to require the Supplier to repair or replace the rejected Goods, or to provide a full refund of the price of the rejected Goods [(if paid)];
          </p>
          <p>
            (d) to refuse to accept any subsequent delivery of the Goods which the Supplier attempts to make;
          </p>
          <p>
            (e) to recover from the Supplier any expenditure incurred by the Customer in obtaining substitute goods from a third party; and
          </p>
          <p>
            (f) to claim damages for any additional costs, loss or expenses incurred by the Customer arising from the Supplier's failure to supply Goods in accordance with clause 3.1.
          </p>
          <p class="numbered-para" data-number="6.3">
            If the Supplier has supplied Services that do not comply with the requirements of clause 5.3(d) then, without limiting or affecting other rights or remedies available to it, the Customer shall have one or more of the following rights and remedies:
          </p>
          <p>
            (a) to terminate the Contract with immediate effect by giving written notice to the Supplier;
          </p>
          <p>
            (b) to return the Deliverables to the Supplier at the Supplier's own risk and expense;
          </p>
          <p>
            (c) to require the Supplier to provide repeat performance of the Services, or to provide a full refund of the price paid for the Services (if paid);
          </p>
          <p>
            (d) to refuse to accept any subsequent performance of the Services which the Supplier attempts to make;
          </p>
          <p>
            (e) to recover from the Supplier any expenditure incurred by the Customer in obtaining substitute services or deliverables from a third party; and
          </p>
          <p>
            (f) to claim damages for any additional costs, loss or expenses incurred by the Customer arising from the Supplier's failure to comply with clause 5.3(d).
          </p>
          <p class="numbered-para" data-number="6.4">
            These Conditions shall extend to any substituted or remedial services and/or repaired or replacement goods supplied by the Supplier.
          </p>
          <p class="numbered-para" data-number="6.5">
            The Customer's rights and remedies under the Contract are in addition to, and not exclusive of, any rights and remedies implied by statute and common law.
          </p>
          <p class="numbered-para" data-number="7.">
            <span class="text-heavy">Customer's obligations</span>
          </p>
          <p class="numbered-para" data-number="7.1">
            The Customer shall:
          </p>
          <p>
            (a) provide the Supplier with reasonable access at reasonable times to the Customer's premises for the purpose of providing the Services; and
          </p>
          <p>
            (b) provide such necessary information for the provision of the Services as the Supplier may reasonably request.
          </p>
          <p class="numbered-para" data-number="8.">
            <span class="text-heavy">Charges and payment</span>
          </p>
          <p class="numbered-para" data-number="8.1">
            The price for the Goods:
          </p>
          <p>
            (a) shall be the price set out in the Order, or if no price is quoted, the price set out in the Supplier's published price list in force at the Commencement Date; and
          </p>
          <p>
            (b) shall be inclusive of the costs of packaging, insurance and carriage of the Goods. No extra charges shall be effective unless agreed in writing and signed by the Customer.
          </p>
          <p class="numbered-para" data-number="8.2">
            The charges for the Services shall be set out in the Order, and shall be the full and exclusive remuneration of the Supplier in respect of the performance of the Services. Unless otherwise agreed in writing by the Customer, the charges shall include every cost and expense of the Supplier directly or indirectly incurred in connection with the performance of the Services.
          </p>
          <p class="numbered-para" data-number="8.3">
            In respect of the Goods, the Supplier shall invoice the Customer on or at any time after completion of delivery. In respect of Services, the Supplier shall invoice the Customer on completion of the Services. Each invoice shall include such supporting information required by the Customer to verify the accuracy of the invoice, including the relevant purchase order number.
          </p>
          <p class="numbered-para" data-number="8.4">
            In consideration of the supply of Goods and/or Services by the Supplier, the Customer shall pay the invoiced amounts within [NUMBER] days of the date of a correctly rendered invoice to a bank account nominated in writing by the Supplier.
          </p>
          <p class="numbered-para" data-number="8.5">
            All amounts payable by the Customer under the Contract are exclusive of amounts in respect of valued added tax chargeable from time to time (VAT). Where any taxable supply for VAT purposes is made under the Contract by the Supplier to the Customer, the Customer shall, on receipt of a valid VAT invoice from the Supplier, pay to the Supplier such additional amounts in respect of VAT as are chargeable on the supply of the Goods and/or Services at the same time as payment is due for the supply of the Goods and/or Services.
          </p>
          <p class="numbered-para" data-number="8.6">
            If a party fails to make any payment due to the other party under the Contract by the due date for payment, then the defaulting party shall pay interest on the overdue sum from the due date until payment of the overdue sum, whether before or after judgment. Interest under this clause will accrue each date at 4% a year above the Bank of England's base rate from time to time, but at 4% a year for any period when the base rate is below 0%.
          </p>
          <p class="numbered-para" data-number="8.7">
            The Supplier shall maintain complete and accurate records of the time spent and materials used by the Supplier in providing the Services, and the Supplier shall allow the Customer to inspect such records at all reasonable times on request.
          </p>
          <p class="numbered-para" data-number="8.8">
            The Customer may at any time, without notice to the Supplier, set off any liability of the Supplier to the Customer against any liability of the Customer to the Supplier, whether either liability is present or future, liquidated or unliquidated, and whether or not either liability arises under the Contract. Any exercise by the Customer of its rights under this clause shall not limit or affect any other rights or remedies available to it under the Contract or otherwise.
          </p>
          <p class="numbered-para" data-number="9.">
            <span class="text-heavy">Intellectual property rights</span>
          </p>
          <p class="numbered-para" data-number="9.1">
            All Intellectual Property Rights in or arising out of or in connection with the Services (other than Intellectual Property Rights in any Customer Materials) shall be owned by the Supplier. 
          </p>
          <p class="numbered-para" data-number="9.2">
            The Supplier grants to the Customer, or shall procure the direct grant to the Customer of, a fully paid-up, worldwide, non-exclusive, royalty-free perpetual and irrevocable licence to copy and modify the Deliverables (excluding Customer Materials) for the purpose of receiving and using the Services and the Deliverables.
          </p>
          <p class="numbered-para" data-number="9.3">
            The Customer grants the Supplier a fully paid-up, non-exclusive, royalty-free non-transferable licence to copy and modify any materials provided by the Customer to the Supplier for the term of the Contract for the purpose of providing the Services to the Customer.
          </p>
          <p class="numbered-para" data-number="9.4">
            The Supplier acknowledges that all rights in the Customer Materials are and shall remain the exclusive property of the Customer.
          </p>
          <p class="numbered-para" data-number="9.5">
            Alternative IPR provisions to follow
          </p>
          <p class="numbered-para" data-number="9.6">
            The Supplier hereby irrevocably, unconditionally and absolutely assigns to the Customer, with full title guarantee, and without restriction, all right, title and interest in and to all existing and future Intellectual Property Rights (including future copyright and design right) subsisting in or relating to:
          </p>
          <p>
            (a) In or relating to all the Deliverables; and 
          </p>
          <p>
            (b) in or arising out of or in connection with the Services
          </p>
          <p>
            whether created, developed or produced before, on or after the Commencement Date, in all countries and with the right to sue for damages and other relief for past infringement of any of those Intellectual Property Rights. For the avoidance of doubt, the assignment under this clause 9 shall take effect from the date on which the relevant Deliverable was or is created, developed or produced.
          </p>
          <p class="numbered-para" data-number="9.7">
            To the extent that this clause 9 is not effective to assign legal title to the Intellectual Property Rights:
          </p>
          <p>
            (a) in or to the Deliverables or 
          </p>
          <p>
            (b) in or arising out of or in connection with the Services, 
          </p>
          <p>
            then the Supplier shall assign to the Customer such Intellectual Property Rights as and when requested by the Customer by executing any assignment documents reasonably requested by the Customer. Until such time as those Intellectual Property Rights are assigned to the Customer, the Supplier shall hold all such Intellectual Property Rights on trust for the Customer and the Customer shall have an exclusive worldwide, royalty-free licence under those Intellectual Property Rights and to use the Deliverables for any purpose.
          </p>
          <p class="numbered-para" data-number="9.8">
            The Supplier warrants that it has obtained or shall obtain from all subcontractors an unconditional assignment without restriction of the legal and beneficial ownership of all existing and future Intellectual Property Rights subsisting:
          </p>
          <p>
            (a) in or relating to any Deliverable; and
          </p>
          <p>
            (b) in or arising out of or in connection with the Services
          </p>
          <p>
            created or developed by such subcontractors, in all countries, and that the assignment under this clause 9 includes the assignment of all of those Intellectual Property Rights.
          </p>
          <p class="numbered-para" data-number="10.">
            <span class="text-heavy">Indemnity</span>
          </p>
          <p class="numbered-para" data-number="10.1">
            The Supplier shall indemnify the Customer against all liabilities, costs, expenses, damages and losses (including any direct, indirect or consequential losses, loss of profit, loss of reputation and all interest, penalties and legal costs (calculated on a full indemnity basis) and all other reasonable professional costs and expenses) suffered or incurred by the Customer arising out of or in connection with: 
          </p>
          <p>
            (a) any claim made against the Customer for actual or alleged infringement of a third party's intellectual property rights arising out of, or in connection with, the manufacture, supply or use of the Goods, or receipt, use or supply of the Services (excluding the Customer Materials);
          </p>
          <p>
            (b) any claim made against the Customer by a third party for death, personal injury or damage to property arising out of, or in connection with, defects in the Goods, as delivered, or the Deliverables; and
          </p>
          <p>
            (c) any claim made against the Customer by a third party arising out of or in connection with the supply of the Goods, as delivered, or the Services.
          </p>
          <p class="numbered-para" data-number="10.1">
            This clause 10 shall survive termination of the Contract.
          </p>
          <p class="numbered-para" data-number="11.">
            <span class="text-heavy">Insurance</span>
          </p>
          <p>
            During the term of the Contract and for a period of [2] years thereafter, the Supplier shall maintain in force, with a reputable insurance company, professional indemnity insurance, product liability insurance and public liability insurance to cover the liabilities that may arise under or in connection with the Contract, and shall, on the Customer's request, produce both the insurance certificate giving details of cover and the receipt for the current year's premium in respect of each insurance. 
          </p>
          <p class="numbered-para" data-number="12.">
            <span class="text-heavy">Confidentiality</span>
          </p>
          <p class="numbered-para" data-number="12.1">
            Each party undertakes that it shall not at any time, disclose to any person any confidential information concerning the business, assets, affairs, customers, clients or suppliers of the other party, except as permitted by clause 12.2.
          </p>
          <p class="numbered-para" data-number="12.2">
            Each party may disclose the other party's confidential information:
          </p>
          <p>
            (a) to its employees, officers, representatives, contractors, subcontractors or advisers who need to know such information for the purposes of exercising the party's rights or carrying out its obligations under the Contract. Each party shall ensure that its employees, officers, representatives, contractors, subcontractors or advisers to whom it discloses the other party's confidential information comply with this clause 12; and
          </p>
          <p>
            (b) as may be required by law, a court of competent jurisdiction or any governmental or regulatory authority.
          </p>
          <p class="numbered-para" data-number="12.3">
            Neither party shall use the other party's confidential information for any purpose other than to exercise its rights and perform its obligations under or in connection with the Contract.
          </p>
          <p class="numbered-para" data-number="13.">
            <span class="text-heavy">Compliance with relevant laws and policies</span>
          </p>
          <p class="numbered-para" data-number="13.1">
            In performing its obligations under the Contract, the Supplier shall:
          </p>
          <p>
            (a) comply with all applicable laws, statutes, regulations and codes from time to time in force; and
          </p>
          <p>
            (b) comply with the Mandatory Policies.
          </p>
          <p class="numbered-para" data-number="14.">
            <span class="text-heavy">Termination</span>
          </p>
          <p class="numbered-para" data-number="14.1">
            Without affecting any other right or remedy available to it, the Customer may terminate the Contract:
          </p>
          <p>
            (a) with immediate effect by giving written notice to the Supplier if:
          </p>
          <p>
            (i) there is a change of Control of the Supplier; or
          </p>
          <p>
            (ii) the Supplier commits a breach of clause 13.
          </p>
          <p>
            (b) for convenience by giving the Supplier [one] months' written notice.
          </p>
          <p class="numbered-para" data-number="14.2">
            Without affecting any other right or remedy available to it, either party may terminate the Contract with immediate effect by giving written notice to the other party if:
          </p>
          <p>
            (a) the other party commits a material breach of any term of the Contract which breach is irremediable or (if such breach is remediable) fails to remedy that breach within a period of [28] days after being notified in writing to do so;
          </p>
          <p>
            (b) the other party takes any step or action in connection with its entering administration, provisional liquidation or any composition or arrangement with its creditors (other than in relation to a solvent restructuring), obtaining a moratorium, being wound up (whether voluntarily or by order of the court, unless for the purpose of a solvent restructuring), having a receiver appointed to any of its assets or ceasing to carry on business; or
          </p>
          <p>
            (c) the other party suspends, or threatens to suspend, or ceases or threatens to cease to carry on all or a substantial part of its business.
          </p>
          <p class="numbered-para" data-number="15.">
            <span class="text-heavy">Consequences of termination</span>
          </p>
          <p class="numbered-para" data-number="15.1">
            On termination of the Contract, the Supplier shall immediately deliver to the Customer all Deliverables whether or not then complete, and return all Customer Materials. If the Supplier fails to do so, then the Customer may enter the Supplier's premises and take possession of them. Until they have been returned or delivered, the Supplier shall be solely responsible for their safe keeping and will not use them for any purpose not connected with the Contract. 
          </p>
          <p class="numbered-para" data-number="15.2">
            Termination of the Contract shall not affect the parties' rights and remedies that have accrued as at termination, including the right to claim damages in respect of any breach of the Contract which existed at or before the date of termination. 
          </p>
          <p class="numbered-para" data-number="15.3">
            Any provision of the Contract that expressly or by implication is intended to come into or continue in force on or after termination of the Contract shall remain in full force and effect.
          </p>
          <p class="numbered-para" data-number="16.">
            <span class="text-heavy">Force majeure</span>
          </p>
          <p>
            Neither party shall be in breach of the Contract or otherwise liable for any failure or delay in the performance of its obligations if such delay or failure results from events, circumstances or causes beyond its reasonable control. The time for performance of such obligations shall be extended accordingly. If the period of delay or non-performance continues for  8 weeks, the party not affected may terminate the Contract by giving 14 days' written notice to the affected party.
          </p>
          <p class="numbered-para" data-number="17.">
            <span class="text-heavy">General</span>
          </p>
          <p class="numbered-para" data-number="17.1">
            <span class="text-heavy">Assignment and other dealings.</span>
          </p>
          <p>
            (a) The Customer may at any time assign, mortgage, charge, subcontract, delegate, declare a trust over or deal in any other manner with all or any of its rights and obligations under the Contract. 
          </p>
          <p>
            (b) The Supplier shall not assign, transfer, mortgage, charge, delegate, declare a trust over or deal in any other manner with any of its rights and obligations under the Contract without the prior written consent of the Customer. 
          </p>
          <p class="numbered-para" data-number="17.2">
            <span class="text-heavy">Subcontracting.</span> The Supplier may not subcontract any or all of its rights or obligations under the Contract without the prior written consent of the Customer. If the Customer consents to any subcontracting by the Supplier, the Supplier shall remain responsible for all the acts and omissions of its subcontractors as if they were its own. 
          </p>
          <p class="numbered-para" data-number="17.3">
            <span class="text-heavy">Notices.</span>
          </p>
          <p>
            (a) Any notice given to a party under or in connection with the Contract shall be in writing and shall be delivered by hand or by pre-paid first-class post or other next working day delivery service at its registered office (if a company) or its principal place of business (in any other case)/
          </p>
          <p>
            (b) Any notice shall be deemed to have been received:
          </p>
          <p>
            (i) if delivered by hand, at the time the notice is left at the proper address;
          </p>
          <p>
            (ii) if sent by pre-paid first-class post or other next working day delivery service, at 9.00 am on the second Business Day after posting; or
          </p>
          <p>
            (iii) if sent by email, at the time of transmission, or, if this time falls outside Business Hours in the place of receipt, when Business Hours resume.
          </p>
          <p>
            (c) This clause does not apply to the service of any proceedings or other documents in any legal action or, where applicable, any arbitration or other method of dispute resolution.
          </p>
          <p class="numbered-para" data-number="17.4">
            <span class="text-heavy">Severance.</span> If any provision or part-provision of the Contract is or becomes invalid, illegal or unenforceable, it shall be deemed deleted, but that shall not affect the validity and enforceability of the rest of the Contract. If any provision of the Contract is deemed deleted the parties shall negotiate in good faith to agree a replacement provision that, to the greatest extent possible, achieves the intended commercial result of the original provision.
          </p>
          <p class="numbered-para" data-number="17.5">
            <span class="text-heavy">Waiver.</span>
          </p>
          <p>
            (a) Except as set out in clause 2.5, a waiver of any right or remedy is only effective if given in writing. 
          </p>
          <p>
            (b) A delay or failure to exercise, or the single or partial exercise of, any right or remedy shall not waive that or any other right or remedy, nor shall it prevent or restrict the further exercise of that or any other right or remedy. 
          </p>
          <p class="numbered-para" data-number="17.6">
            <span class="text-heavy">No partnership or agency.</span> Nothing in the Contract is intended to, or shall be deemed to, establish any partnership or joint venture between the parties, constitute either party the agent of the other, or authorise either party to make or enter into any commitments for or on behalf of the other party. Each party confirms it is acting on its own behalf and not for the benefit of any other person.
          </p>
          <p class="numbered-para" data-number="17.7">
            <span class="text-heavy">Entire agreement.</span>
          </p>
          <p>
            (a) The Contract constitutes the entire agreement between the parties.
          </p>
          <p>
            (b) Each party acknowledges that in entering into the Contract it does not rely on any statement, representation, assurance or warranty (whether made innocently or negligently) that is not set out in the Contract. Each party agrees that it shall have no claim for innocent or negligent misrepresentation [or negligent misstatement] based on any statement in the Contract.]
          </p>
          <p class="numbered-para" data-number="17.8">
            <span class="text-heavy">Third party rights.</span>
          </p>
          <p>
            (a) The Contract does not give rise to any rights under the Contracts (Rights of Third Parties) Act 1999 to enforce any term of the Contract.
          </p>
          <p>
            (b) The rights of the parties to rescind or vary the Contract are not subject to the consent of any other person.
          </p>
          <p class="numbered-para" data-number="17.9">
            <span class="text-heavy">Variation.</span> Except as set out in these Conditions, no variation of the Contract, including the introduction of any additional terms and conditions, shall be effective unless it is agreed in writing and signed by the parties or their authorised representatives.
          </p>
          <p class="numbered-para" data-number="17.10">
            <span class="text-heavy">Governing law.</span> The Contract, and any dispute or claim (including non-contractual disputes or claims) arising out of or in connection with it or its subject matter or formation shall be governed by and construed in accordance with the law of England and Wales.
          </p>
          <p class="numbered-para" data-number="17.11">
            <span class="text-heavy">Jurisdiction.</span> Each party irrevocably agrees that the courts of England and Wales shall have exclusive jurisdiction to settle any dispute or claim (including non-contractual disputes or claims) arising out of or in connection with the Contract or its subject matter or formation.
          </p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'PoTermsConditions',
};
</script>
